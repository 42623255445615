<template>
  <div class="hotRecommend">
    <div class="itemContent">
      <div
        class="item"
        @click="buyGoods(item)"
        v-for="(item, index) in goodsList"
        :key="index"
      >
        <img :src="item.main_imgs" class="goods" alt="" />
        <div class="goods-info">
          <div class="goods-title two">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        goodsList:{
            type:Array
        }
    },
    data () {
        return {}
    },
    methods:{
        buyGoods (val) {
            this.$emit('buyGoods',val)
        }
    }
}
</script>

<style lang="less" scoped>
.hotRecommend {
  margin-top: 15px;
  // padding-top: 2px;
  padding: 2px;
  // padding-bottom: 60px;
  .title {
    padding: 0 15px;
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    border-radius: 2px;
    height: 20px;
    display: flex;
    align-items: center;
    .biu {
      width: 4px;
      height: 15px;
      background-color: #2163e9;
      border-radius: 2px;
    }
    .text {
      padding-left: 10px;
    }
  }
  .itemContent {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0 10px; // 剧中
    columns: 2;
    column-gap: 10px; // 列间距
    background-color: #f8f8f8;
    .item {
      margin-bottom: 10px;
      position: relative;
      background-color: #fff;
      width: 100%;
      break-inside: avoid;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(0, 96, 240, 0.1);
      .goods {
        width: 100%;
      }
      .eyeIcon {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #999;
        color: #fff;
        font-size: 10px;
        padding: 4px 6px;
        border-radius: 9px;
      }
      .goods-info {
        padding: 12px 10px 10px 10px;
        .goods-title {
          font-size: 14px;
          font-weight: 510;
        }
        .goods-user {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #bababa;
          .flex-user-style {
            .img-title {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #ccc;
              margin-right: 5px;
            }
          }
          .like {
            .good-style {
              width: 12px;
              margin-right: 3px;
            }
            .msg {
              align-items: center;
            }
          }
        }
      }
    }
  }
}
</style>
