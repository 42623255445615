<template>
  <!-- 热门推荐 -->
  <div class="hotRecommend">
    <div class="recContent">
      <div
        class="recItem"
        v-for="(item,index) in recList"
        :key="index"
        @click="checkDetail(item.id)"
      >
        <div class="recT">
          <img :src="item.main_imgs" class="shopImg" alt="" />
        </div>
        <div class="recC two">{{item.title}}</div>
        <div class="footer">
          <div class="recF flex">
            <span class="number">￥{{item.price}}</span>
            <span class="payment">0人付款</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        recList:{
            type:Array,
        }
    },
    data () {
        return {}
    },
    created () {},
    methods:{
        checkDetail(val) {
            this.$emit('checkDetail',val)
        }
    }
}
</script>

<style lang="less" scoped>
.hotRecommend {
  .recContent {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    flex-wrap: wrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .recItem {
      position: relative;
      width: 165px;
      display: inline-block;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      margin-bottom: 10px;
      box-shadow: 0px 1px 10px 1px rgba(0, 96, 240, 0.1);
      padding-bottom: 20px;
      .recT {
        .shopImg {
          width: 100%;
        }
      }
      .recC {
        padding: 0 10px 0 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #333333;
      }
      .footer {
        position: absolute;
        bottom: -56px;
        margin-bottom: 50px;
        .recF {
          width: 145px;
        padding: 0 10px 10px;
        .number {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #e12828;
        }
        .payment {
          text-align: right;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
        }
      }
      }
      
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
