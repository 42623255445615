<template>
  <div class="titleL">
      <div class="title">
          <span class="biu"></span>
          <div class="text">{{title}}</div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        }
    }
}
</script>

<style lang="less" scoped>
    .titleL {
        .title {
        padding: 0 15px;
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        border-radius: 2px;
        height: 20px;
        display: flex;
        align-items: center;
        .biu {
          width: 4px;
          height: 15px;
          background-color: #2163e9;
          border-radius: 2px;
        }
        .text {
          padding-left: 10px;
        }
      }
    }
</style>