<template>
  <div class="medication">
    <div class="search">
        <van-search
          v-model="search_ward"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
        >
          <template #action>
            <div @click="search" class="seachStyle">搜索</div>
          </template>
        </van-search>
      </div>
    <div v-if="is_show == true">
      <vaeTitle title="眼病类型" class="typeTitle"></vaeTitle>
      <div class="content">
        <div class="typeLabel">
          <van-swipe class="my-swipe" :loop="false"  @change="swipeChange">
            <van-swipe-item>
              <van-grid square :border="false" :column-num="5">
                <van-grid-item
                  @click="classItemList(item)"
                  v-for="item in cate_list"
                  :key="item.id"
                >
                  <template #text>
                    <div class="txt">{{ item.title }}</div>
                  </template>
                  <template #icon>
                    <img :src="item.img" class="icon" alt="" />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
            <van-swipe-item>
              <van-grid square :border="false" :column-num="5">
                <van-grid-item
                  @click="classItemList(item)"
                  v-for="item in cate_list"
                  :key="item.id"
                >
                  <template #text>
                    <div class="txt">{{ item.title }}</div>
                  </template>
                  <template #icon>
                    <img :src="item.img" class="icon" alt="" />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <vaeTitle :title="headerTitle" class="typeTitle"></vaeTitle>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" :offset="1" @load="productLoad" directio="down" >
          <vaeRecommend :recList="product_list" @checkDetail="recDetail" ></vaeRecommend>
      </van-list>
    </div>
    <div class="hotRecommend" v-else>
      <div class="title">
        <span class="biu"></span>
        <div class="text">搜索列表</div>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="productLoad" >
        <vaeRecommend :recList="product_list" @checkDetail="recDetail" ></vaeRecommend>
      </van-list>
    </div>
  </div>
</template>

<script>
import vaeTitle from '@/components/title.vue'
import vaeGoods from '@/components/hotGoods.vue'
import { cateList,catezList,productList } from '@/api/studentProduct'
import vaeRecommend from '@/components/hotRecommend.vue'
export default {
  name: 'medication',
  components: {
    vaeTitle,
    vaeGoods,
    vaeRecommend
  },
  data () {
    return {
      cate_list: [],
      product_list: [],
      is_show:true,
      search_ward:'',
      startPage:1,
      listObj:{
        type:1,
        page:1,
        search_word:''
      },
      headerTitle:'综合药物',
      loading: false,
      finished: false,
      countAll:null,
      listQuery: {
				page: 1, // 页数
				limit: 10
			},
    }
  },
  created () {
    this.getList(this.startPage)
    this.getCatezList(this.listObj)
  },
  watch:{
    'search_ward':{
      handler(val){
        if(val == '' ) {
          this.is_show = true
         this.getList(this.startPage)
         this.listObj.search_word = ''
         this.headerTitle = '综合药物'
         this.getCatezList(this.listObj)
        }
      }
    }
  },
  methods: {
    async getList (page) {
      const res = await cateList({ type: 1 ,page:page })
      if (res.data.code == 200) {
        this.cate_list = res.data.data
      }
    },
    async getCatezList (obj) {
      const res = await catezList(obj)
      if (res.data.code == 200) {
        res.data.data.list.forEach(element => {
          this.product_list.push(element)
        });
        if(this.product_list.length >= res.data.data.count) {
          this.finished =  true
        }
        this.countAll = res.data.data.count
      }
    },
    // 分页
    productLoad() {
       if(this.listObj.page >= Math.ceil(this.countAll/10) ) {
         this.loading =  false
       }
       this.loading =  true
       setTimeout(() => {
         this.listObj.page = this.listObj.page + 1;
        this.getCatezList(this.listObj)
       }, 1000);
      this.loading =  false
    },
    search () {
      this.listObj.search_word = this.search_ward
      this.getCatezList (this.listObj)
        this.is_show = false
    },
    buyGoods (val) {
      this.$router.push({
        path: '/drugDetail',
        query: {
          id: val.id
        }
      })
    },
    // 商品详情
    recDetail (val) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          id: val
        }
      })
    },
    // 分类宫格点击
    async classItemList (val) {
      this.headerTitle = val.title
      const obj = {
        cate_id:val.id,
        page:1
      }
      const res = await productList(obj)
      if (res.data.code == 200) {
        if(this.product_list) {
          this.product_list = []
        }
        res.data.data.list.forEach(element => {
          this.product_list.push(element)
        });
        if(this.product_list.length >= res.data.data.count) {
          this.finished =  true
        }
        this.countAll = res.data.data.count
      }
    },
    swipeChange (i) {
      this.getList(i+1)
    }
  }
}
</script>

<style lang="less" scoped>
.medication {
  .hotRecommend {
    margin-top: 15px;
    .title {
      padding: 0 15px;
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      border-radius: 2px;
      height: 20px;
      display: flex;
      align-items: center;
      .biu {
        width: 4px;
        height: 15px;
        background-color: #2163e9;
        border-radius: 2px;
      }
      .text {
        padding-left: 10px;
      }
    }
    .itemContent {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 0 10px; // 剧中
      columns: 2;
      column-gap: 10px; // 列间距
      background-color: #f8f8f8;
      .item {
        position: relative;
        background-color: #fff;
        width: 172px;
        break-inside: avoid;
        border-radius: 0 0 10px 10px;
        overflow: hidden;
        box-shadow: 0px 3px 6px rgba(0, 96, 240, 0.1);
        .goods {
          width: 100%;
        }
        .eyeIcon {
          position: absolute;
          top: 5px;
          left: 5px;
          background-color: #999;
          color: #fff;
          font-size: 10px;
          padding: 4px 6px;
          border-radius: 9px;
        }
        .goods-info {
          padding: 12px 10px 10px 10px;
          .goods-title {
            font-size: 14px;
            font-weight: 510;
          }
          .goods-user {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #bababa;
            .flex-user-style {
              .img-title {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #ccc;
                margin-right: 5px;
              }
            }
            .like {
              .good-style {
                width: 12px;
                margin-right: 3px;
              }
              .msg {
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
  .content {
    margin-top: 15px;
    padding: 0 15px;
    .typeLabel {
      font-size: 14px;
      color: #333333;
      height: 150.5px;
      margin-top: 15px;
      border-radius: 10px;
      background-color: #fff;
      overflow: hidden;
      overflow-y: scroll;
      .text {
        margin-top: 6px;
        font-size: 12px;
        color: #333333;
        line-height: 22px;
      }
      .icon {
          width: 30px;
          height: 30px;
        }
        .txt {
          margin-top: 5px;
          font-size: 12px;
        }
        .txt1 {
          font-size: 11px;
        }
    }
  }
  .typeTitle {
    margin-top: 15px;
  }
  .img30 {
    width: 30px;
    height: 30px;
  }
  ::v-deep .van-grid-item__content {
    padding: 8px;
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  ::v-deep .van-swipe__indicator--active {
    background-color: #2163e9 !important;
  }
  ::v-deep .van-grid {
    position: relative;
  }
  ::v-deep .van-swipe__indicators {
    position: absolute;
    bottom: -1px;
  }
  ::v-deep .van-swipe__indicator {
    width: 20px;
    height: 3px;
    z-index: 999;
    border-radius: 0% !important;
  }
  ::v-deep .van-grid-item__content {
    padding: 0;
  }
}
</style>
